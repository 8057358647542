<template>
  <div class="login-box">
    <div class="login-title">
      请登录
    </div>
    <div style="margin-top:20px">
      <form action="" >
        <van-cell-group>
          <van-field v-model="phone" placeholder="请输入用户名" size="large" />
          <van-field
            v-model="userPwd"
            type="password"
            placeholder="请输入密码"
            size="large"
          />
        </van-cell-group>
        <div style="display:flex;justify-content:flex-end">
          <van-button
            style="border:none;color:#4D6FF0"
            size="small"
            @click="handleLinkToForgetPwd"
            >忘记密码?</van-button
          >
        </div>
        <div class="login-btn">
          <van-button
            block
            round
            @click="handleLogin"
            type="primary"
            >登录</van-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { reactive, toRefs } from "vue";
import { Toast } from "vant";
export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const data = reactive({
      phone: "",
      userPwd: "",
      typeId: 7,
    });
    const handleLinkToForgetPwd = () => {
      router.push({ path: "/forgetPwd" });
    };
    const handleLogin = () => {
      Toast.loading({ duration: 300, message: "登录中..." });
      store
        .dispatch("Login", data)
        .then((res) => {
          Toast.clear();
          console.log(res);
          router.replace("merchantSelect");
          // if (res.session.lastLoginMerchantId) {
          //   router.replace("/");
          // } else {

          // }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    return {
      handleLogin,
      handleLinkToForgetPwd,
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.login-box {
  text-align: left;
  margin: 80px 8px;
  .login-title {
    font-weight: 600;
    font-size: 30px;
    line-height: 42px;
    color: #171725;
    margin-left: 10px;
  }
  .login-form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-btn {
    display: flex;
    justify-content: center;
    button {
      width: 211px;
      height: 48px;
      margin-top: 40px;
    }
  }
}
</style>
